import { useState, useEffect, useRef, useMemo, forwardRef, useImperativeHandle } from 'react';
import { textRenderer } from 'handsontable/renderers/textRenderer';
import HotTable from '@handsontable/react';
import Handsontable from 'handsontable';
import { Button, ButtonGroup, Intent, useHotkeys } from '@blueprintjs/core';

import axios from '../../../shared/custom-axios';
import { AxiosResponse } from 'axios';
import { ExchangeType, NFT, NFTsResponse, Opportunity, StrategyType, TradingPair } from '../../../shared/interfaces/bot';
import { OpportunitiesEditorTable } from './table';
import { CellValue } from 'handsontable/common';

const COLUMNS_FOR_NORMAL_TRADING = [
  'percentage',
  'quantity',
  'is_flip',
  'offset',
  'max_individual',
  'emergency_cancel',
  'offset_ec',
  'side',
  'quota',
  'replenish_delay',
  'debounce',
  'mode',
  'exit',
  'remove',
  'id',
];

const COLUMNS_FOR_PAIRS_TRADING = [
  'ratio',
  'quantity',
  'is_flip',
  'offset',
  'max_individual',
  'emergency_cancel',
  'offset_ec',
  'side',
  'quota',
  'replenish_delay',
  'debounce',
  'mode',
  'exit',
  'remove',
  'id',
];

const COLUMNS_FOR_DEFI = ['percentage', 'quantity', 'offset', 'defi_slippage', 'side', 'quota', 'remove', 'id'];

const COLUMNS_FOR_NFT = [
  'nft_mode',
  'nft_exchange_rate',
  'nft_token_id',
  'percentage',
  'quantity',
  'offset',
  'emergency_cancel',
  'offset_ec',
  'side',
  'quota',
  'debounce',
  'remove',
  'id',
];
const STOP_LOSS_ACTIVE_COLUMNS = [0, 1, 3, 7, 10, 12];
const STOP_LOSS_SIDES = ['long_stop_loss', 'short_stop_loss'];

// Validation rules
const MIN_DEBOUNCE = 1000;

const debounceValidator = (value: any, callback: any) => {
  return callback(value >= MIN_DEBOUNCE);
};

interface Props {
  tradingPair: TradingPair;
  items: Opportunity[];
  onUpdate: (tradingPairId: number, payload: any) => void;
}

export const OpportunitiesEditor = forwardRef<any, Props>(({ tradingPair, items, onUpdate }: Props, ref) => {
  const hotTableComponent = useRef<HotTable | null>(null);
  const { bot_id, currency } = tradingPair;
  const [isShowId, setIsShowId] = useState(false);
  const isNftTrading = tradingPair.strategy_type === StrategyType.NFTMarketMaking;
  const isDeFiTrading =
    tradingPair.primary_instrument.exchange_type === ExchangeType.DEX ||
    tradingPair.secondary_instrument.exchange_type === ExchangeType.DEX;
  const isPairsTrading = tradingPair.strategy_type === StrategyType.PairsTrading;
  const [primaryNfts, setPrimaryNfts] = useState<Array<NFT>>([]);
  let currencyInput = '';

  if (isDeFiTrading) {
    currencyInput = '';
  } else {
    currencyInput = `(${currency})`;
  }

  useEffect(() => {
    const botId = tradingPair.bot_id;
    const accountId = tradingPair.primary_account.id;
    const collectionId = tradingPair.primary_instrument.pair_address;
    const path = `/api/accounts/${accountId}/nfts?collection_id=${collectionId}&bot_id=${botId}`;

    axios.get<NFTsResponse>(path).then((response: AxiosResponse<NFTsResponse>) => {
      const items = response.data.data;

      setPrimaryNfts(items);
    });
  }, [tradingPair]);

  const tableColumns = useMemo(() => {
    let primaryNftIds = primaryNfts.map((nft) => nft.token_id);

    const columns = [
      {
        field_id: 'nft_mode',
        title: 'Mode',
        settings: {
          editor: 'select',
          selectOptions: ['normal', 'exchange_rate'],
        },
      },
      {
        field_id: 'nft_exchange_rate',
        title: 'Exchange Rate',
        settings: {
          type: 'numeric',
          numericFormat: {
            pattern: '0,0',
            culture: 'en-US',
          },
        },
      },
      {
        field_id: 'nft_token_id',
        title: 'NFT Token ID',
        settings: {
          editor: 'select',
          selectOptions: primaryNftIds,
        },
      },
      {
        field_id: 'percentage',
        title: '%',
        settings: {
          type: 'numeric',
        },
      },
      {
        field_id: 'ratio',
        title: 'Ratio',
        settings: {
          type: 'numeric',
        },
      },
      {
        field_id: 'quantity',
        title: `Qty ${currencyInput}`,
        settings: {
          type: 'numeric',
          numericFormat: {
            pattern: '0,0',
            culture: 'en-US',
          },
        },
      },
      {
        field_id: 'is_flip',
        title: 'Flip',
        settings: {
          editor: 'select',
          selectOptions: ['true', 'false'],
          className: 'custom-cell',
        },
      },
      {
        field_id: 'offset',
        title: `Offset ${currencyInput}`,
        settings: {
          type: 'numeric',
          numericFormat: {
            pattern: '0,0',
            culture: 'en-US',
          },
        },
      },
      {
        field_id: 'max_individual',
        title: `Max Indiv ${currencyInput}`,
        settings: {
          type: 'numeric',
          numericFormat: {
            pattern: '0,0',
            culture: 'en-US',
          },
        },
      },
      {
        field_id: 'emergency_cancel',
        title: 'EC (%)',
        settings: {
          type: 'numeric',
        },
      },
      {
        field_id: 'offset_ec',
        title: 'Offset EC (%)',
        settings: {
          type: 'numeric',
        },
      },
      {
        field_id: 'defi_slippage',
        title: `Slippage (%)`,
        settings: {
          type: 'numeric',
        },
      },
      {
        field_id: 'side',
        title: 'Side',
        settings: {
          editor: 'select',
          selectOptions: ['short', 'long', 'short_stop_loss', 'long_stop_loss'],
          className: 'custom-cell',
        },
      },
      {
        field_id: 'quota',
        title: 'Quota',
        settings: {
          type: 'numeric',
        },
      },
      {
        field_id: 'replenish_delay',
        title: 'Replenish Delay (ms)',
        settings: {
          type: 'numeric',
          numericFormat: {
            pattern: '0,0',
            culture: 'en-US',
          },
        },
      },
      {
        field_id: 'debounce',
        title: 'Debounce (ms)',
        settings: {
          type: 'numeric',
          validator: debounceValidator,
          numericFormat: {
            pattern: '0,0',
            culture: 'en-US',
          },
        },
      },
      {
        field_id: 'mode',
        title: 'Mode',
        settings: {
          editor: 'select',
          selectOptions: ['bait', 'tight'],
        },
      },
      {
        field_id: 'exit',
        title: 'Exit',
        settings: {
          editor: 'select',
          selectOptions: [],
        },
      },
      {
        field_id: 'remove',
        title: '',
        settings: {
          renderer(instance: any, td: any, row: any, col: any, prop: any, value: any, cellProperties: any) {
            textRenderer.apply(this, arguments as any);
            td.innerHTML =
              '<svg data-icon="trash" width="15" height="15" viewBox="0 0 16 16" fill="#db3737">' +
              '<path d="M14.49 3.99h-13c-.28 0-.5.22-.5.5s.22.5.5.5h.5v10c0 .55.45 1 1 1h10c.55 0 1-.45 1-1v-10h.5c.28 0 .5-.22.5-.5s-.22-.5-.5-.5zm-8.5 9c0 .55-.45 1-1 1s-1-.45-1-1v-6c0-.55.45-1 1-1s1 .45 1 1v6zm3 0c0 .55-.45 1-1 1s-1-.45-1-1v-6c0-.55.45-1 1-1s1 .45 1 1v6zm3 0c0 .55-.45 1-1 1s-1-.45-1-1v-6c0-.55.45-1 1-1s1 .45 1 1v6zm2-12h-4c0-.55-.45-1-1-1h-2c-.55 0-1 .45-1 1h-4c-.55 0-1 .45-1 1v1h14v-1c0-.55-.45-1-1-1z" fill-rule="evenodd"></path>' +
              '</svg>';
            td.className = 'remove-opportunity';

            return td;
          },
        },
      },
      {
        field_id: 'id',
        title: 'ID',
        settings: {
          type: 'numeric',
        },
      },
    ];

    return columns.filter(({ field_id }) => {
      if (isNftTrading) {
        return COLUMNS_FOR_NFT.includes(field_id);
      }

      if (isDeFiTrading) {
        return COLUMNS_FOR_DEFI.includes(field_id);
      }

      if (isPairsTrading) {
        return COLUMNS_FOR_PAIRS_TRADING.includes(field_id);
      }

      return COLUMNS_FOR_NORMAL_TRADING.includes(field_id);
    });
  }, [currencyInput, isPairsTrading, isDeFiTrading, primaryNfts]);

  const [tableSettings, setTableSettings] = useState<Handsontable.GridSettings>({
    colHeaders: tableColumns.map(({ title }) => title),
    rowHeaders: true,
    columns: tableColumns.map(({ settings }) => settings),
    stretchH: 'all',
    hiddenColumns: {
      columns: [tableColumns.length - 1],
    },
    data: [
      [1, 50, false, 12000, 1000, 3, 3000, 0.7, 0.5, 'short', 50000, 'bait', null, 1],
      [2, 20, false, 15000, 1000, 3, 3000, 0.7, 0.5, 'long', 3000, 'tight', null, 2],
    ],
    afterOnCellMouseDown: function (e, coords, TD) {
      if (TD.classList.contains('remove-opportunity')) {
        hotTableComponent?.current?.hotInstance?.alter('remove_row', coords.row);
      }
    },
    beforeOnCellMouseDown: function (e, coords, TD) {
      const tableInstance = hotTableComponent?.current?.hotInstance;
      const exitCol = COLUMNS_FOR_PAIRS_TRADING.indexOf('exit');
      if (coords.col === exitCol && tableInstance) {
        tableInstance.updateSettings({
          cells(row, col) {
            const cellProperties = { selectOptions: ['OFF'] };
            const tableData: CellValue[] = tableInstance.getData();
            const sideColumn = COLUMNS_FOR_PAIRS_TRADING.indexOf('side');
            if (col === exitCol && tableData[row]) {
              const side = tableData[row][sideColumn] === 'long' ? 'short' : 'long';
              const opportunities = tableData.filter((row: any[]) => {
                return row[sideColumn] === side;
              });
              const exitOptions = getExitOptions(opportunities);
              cellProperties.selectOptions = ['OFF', ...exitOptions];
            }
            return cellProperties;
          },
        });
      }
    },
    beforePaste: (data, coords) => {
      data.forEach((row) => {
        // Remove ID cell to avoid duplication
        if (row.length >= tableColumns.length) {
          const debounceIndex = tableColumns.findIndex((col: any) => col.field_id === 'debounce');
          row.splice(row.length - 1, 1, '');
          row.splice(debounceIndex, 1, tradingPair.default_debounce);
        }
      });
    },
    beforeChange: (changes) => {
      let ecColumnIndex = tableColumns.findIndex((column) => column.field_id === 'emergency_cancel');
      let offsetEcColumnIndex = tableColumns.findIndex((column) => column.field_id === 'offset_ec');
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      let [row, changedColumn, oldValue, newValue] = changes[0];

      // Either EC or Offset EC cells changes [*]
      if (newValue && (changedColumn === ecColumnIndex || changedColumn === offsetEcColumnIndex)) {
        changes[0][3] = String(Math.abs(newValue));
      }

      if (STOP_LOSS_SIDES.indexOf(String(changes[0][2])) >= 0 || STOP_LOSS_SIDES.indexOf(String(changes[0][3])) >= 0) {
        const shouldDisabled = STOP_LOSS_SIDES.indexOf(String(changes[0][3])) >= 0;
        hotTableComponent?.current?.hotInstance?.updateSettings({
          cells(row, col) {
            const cellProperties = { readOnly: false };
            if (row === Number(changes[0][0]) && STOP_LOSS_ACTIVE_COLUMNS.indexOf(col) < 0) {
              cellProperties.readOnly = shouldDisabled;
            }
            return cellProperties;
          },
        });

        const debounceCol = tableColumns.findIndex((col: any) => col.field_id === 'debounce');
        const quotaCol = tableColumns.findIndex((col: any) => col.field_id === 'quota');
        if (shouldDisabled) {
          hotTableComponent?.current?.hotInstance?.setDataAtCell([[changes[0][0], debounceCol, 1000]]);
          hotTableComponent?.current?.hotInstance?.setDataAtCell([[changes[0][0], quotaCol, 1]]);
        } else {
          hotTableComponent?.current?.hotInstance?.setDataAtCell([[changes[0][0], debounceCol, tradingPair.default_debounce]]);
          hotTableComponent?.current?.hotInstance?.setDataAtCell([[changes[0][0], quotaCol, 0]]);
        }
      }
    },
    contextMenu: {
      items: {
        row_above: {
          name: 'Insert 1 above',
        },
        row_below: {
          name: 'Insert 1 below',
        },
        remove_row: {
          name: 'Delete row',
        },
        separator: Handsontable.plugins.ContextMenu.SEPARATOR,
        clear_custom: {
          name: 'Clear all cells (custom)',
          callback: function () {
            hotTableComponent?.current?.hotInstance?.clear();
          },
        },
      },
    },
    width: 'auto',
    height: 'auto',
    licenseKey: 'non-commercial-and-evaluation',
  });

  useEffect(() => {
    // NOTE: Looks like when the dialog (containing the Hansontable) hasn't finished its animation,
    // the table has already tried to render with incorrect width so here we delay the rendering of the table a bit
    setTimeout(() => {
      setTableSettings((prevState) => {
        if (!tradingPair) return prevState;

        let customBorders: Array<any> = [];

        // Raw data to be shown on the table
        let new_opportunities = items.map((opportunity: any) => {
          return tableColumns.map(({ field_id: fieldId }) => {
            return opportunity[fieldId];
          });
        });

        // Show a separator line between short and long groups
        const shortOpportunityPresent = items.some((opportunity) => opportunity.side === 'short');
        const longOpportunityPresent = items.some((opportunity) => opportunity.side === 'long');

        if (shortOpportunityPresent && longOpportunityPresent) {
          // First "long" opportunity would be the row for a separator
          const separatorIndex = items.findIndex((opportunity) => opportunity.side === 'long');

          customBorders = [
            {
              range: {
                from: {
                  row: separatorIndex,
                  col: 0,
                },
                to: {
                  row: separatorIndex,
                  col: tableColumns.length - 1,
                },
              },
              top: {
                width: 2,
                color: '#9ca3af',
              },
            },
          ];
        }

        return {
          ...prevState,
          data: new_opportunities,
          customBorders: customBorders,
        };
      });

      disableStopLossColumns();
    }, 250);
  }, [tradingPair, items, tableColumns]);

  useEffect(() => {
    setTableSettings((prevState) => {
      if (isShowId) {
        return {
          ...prevState,
          columns: tableColumns.map(({ settings }) => settings),
          hiddenColumns: undefined,
        };
      }

      return {
        ...prevState,
        columns: tableColumns.map(({ settings }) => settings),
        hiddenColumns: {
          columns: [tableColumns.length - 1],
        },
      };
    });
  }, [tableColumns, isShowId]);

  const hotkeys = useMemo(
    () => [
      {
        combo: 'shift+.',
        global: true,
        group: 'Edit opportunity settings',
        label: 'Show/hide IDs column',
        onKeyDown: () => setIsShowId(true),
        onKeyUp: () => setIsShowId(false),
        preventDefault: true,
      },
    ],
    [],
  );

  const { handleKeyDown, handleKeyUp } = useHotkeys(hotkeys);

  const handleTableNew = () => {
    setTableSettings((prevState) => {
      const { data } = prevState;
      const debounceIndex = tableColumns.findIndex((col: any) => col.field_id === 'debounce');
      const defaultValues = Array.from({ length: tableColumns.length }, (_, i) =>
        i === debounceIndex ? tradingPair.default_debounce : '',
      );

      if (!data) {
        return {
          ...prevState,
          data: [defaultValues],
        };
      }

      return {
        ...prevState,
        data: [...data, defaultValues],
      };
    });
  };

  const handleTableSubmit = (tradingPairId: number) => {
    // Validate all cells, by invoking validator for each one, before submitting data
    hotTableComponent?.current?.hotInstance?.validateCells((isValid) => {
      if (!isValid) return;

      const data = hotTableComponent?.current?.hotInstance?.getSourceData() || [];

      const payload = data.map((row) => {
        const d = tableColumns.reduce((acc: any, column: any, index: number) => {
          const { field_id: fieldId } = column;
          // Make undefined values become "" so they won't be discarded in HTTP payload
          const cellValue = row[index] !== undefined ? row[index] : '';
          return { ...acc, [fieldId]: cellValue };
        }, {});

        return { ...d, trading_pair_id: tradingPairId, bot_id: bot_id };
      });

      onUpdate(tradingPairId, payload);
    });
  };

  const disableStopLossColumns = () => {
    const cellValues = hotTableComponent?.current?.hotInstance?.getData();
    if (!Array.isArray(cellValues) || cellValues.length === 0) {
      return;
    }
    const sideCol = tableColumns.findIndex((col: any) => col.field_id === 'side');
    hotTableComponent?.current?.hotInstance?.updateSettings({
      cells(row, col) {
        const shouldDisabled = !Array.isArray(cellValues[row]) ? false : STOP_LOSS_SIDES.indexOf(cellValues[row][sideCol]) >= 0;
        const cellProperties = { readOnly: false };
        if (STOP_LOSS_ACTIVE_COLUMNS.indexOf(col) < 0) {
          cellProperties.readOnly = shouldDisabled;
        }
        return cellProperties;
      },
    });
  };

  const getExitOptions = (allExitOpportunity: any[]) => {
    return allExitOpportunity.map((_el: any, index: number) => {
      const i = index + 1;
      const j = i % 10;
      const k = i % 100;
      if (j === 1 && k !== 11) {
        return i + 'st';
      }
      if (j === 2 && k !== 12) {
        return i + 'nd';
      }
      if (j === 3 && k !== 13) {
        return i + 'rd';
      }
      return i + 'th';
    });
  };

  // Parent of this component will trigger REST API submission by calling
  // this component's handleTableSubmit function
  useImperativeHandle(ref, () => ({
    handleTableSubmit,
  }));

  return (
    <div onKeyDown={handleKeyDown} onKeyUp={handleKeyUp} className="w-full">
      <ButtonGroup className="mb-2">
        <Button text="Add" onClick={() => handleTableNew()} intent={Intent.NONE} icon="add" small={true} />
      </ButtonGroup>

      <OpportunitiesEditorTable settings={tableSettings} ref={hotTableComponent} />
    </div>
  );
});
